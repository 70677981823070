import { Controller } from '@hotwired/stimulus';
import fetchTurboFrame from '../utils';

export default class extends Controller {
  static targets = [
    'printMethodSelect',
    'newPersonalizationNameName',
    'newPersonalizationNameNumber',
    'newPersonalizationNameSize',
    'multiplePersonalizationNames',
    'multiplePersonalizationColumns',
    'multiplePersonalizationDefaultSize',
  ];

  static values = {
    id: Number,
    url: String,
  };

  addMultiplePersonalizations() {
    fetchTurboFrame(`/personalization_lists/multiple_personalizations?${this.multipleParams()}`, 'multiple_personalizations');
  }

  addPersonalizationName() {
    fetchTurboFrame(`/personalization_lists/new_personalization_name?${this.newParams()}`, 'new_personalization_name');
  }

  selectChange() {
    fetchTurboFrame(`${this.urlValue}?${this.selectParams()}`);
  }

  multipleParams() {
    const params = new URLSearchParams();
    params.append('personalization_names', this.multiplePersonalizationNamesTarget.value);
    params.append('column_order', this.multiplePersonalizationColumnsTarget.value);
    params.append('default_size', this.multiplePersonalizationDefaultSizeTarget.value);
    return params;
  }

  newParams() {
    const params = new URLSearchParams();
    params.append('personalization_name[name]', this.newPersonalizationNameNameTarget.value);
    params.append('personalization_name[number]', this.newPersonalizationNameNumberTarget.value);
    params.append('personalization_name[size]', this.newPersonalizationNameSizeTarget.value);

    return params;
  }

  selectParams() {
    const params = new URLSearchParams();
    if (this.idValue !== 0) {
      params.append('id', this.idValue);
    }
    params.append('print_type', this.printMethodSelectTarget.value);
    return params;
  }
}
