import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['shippingAccount'];

  static values = {};

  selectAddressKind(event) {
    const thirdParty = event.currentTarget.value === 'third_party';
    this.shippingAccountTarget.hidden = !thirdParty;
  }
}
