import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['receiptQuantity'];

  prefillReceipt() {
    this.receiptQuantityTargets.forEach((target) => {
      target.querySelector('input[name="receiving_record[product_sizes_attributes][][received]"]')
        .value = target.querySelector('input[name="receiving_record[product_sizes_attributes][][quantity]"]').value;
    });
  }
}
