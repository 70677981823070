import { Controller } from '@hotwired/stimulus';

const inputName = 'quote[artworks_attributes][][color_count]';
const inputTarget = { quick_quote_target: 'colorCount' };
const inputStitchCount = () => {
  // create a new input element
  const input = document.createElement('input');
  input.type = 'text';
  input.name = inputName;
  input.className = 'form-control form-control-sm';
  input.data = inputTarget;

  return input;
};

const inputColorCount = () => {
  // create a new input element
  const select = document.createElement('select');
  select.name = inputName;
  select.className = 'form-select form-select-sm';
  select.data = inputTarget;
  // create a set of options to append to the select element, with a range of 1..12
  select.appendChild(document.createElement('option'));
  for (let i = 1; i <= 12; i += 1) {
    const option = document.createElement('option');
    option.value = i;
    option.textContent = i;
    select.appendChild(option);
  }

  return select;
};

export default class extends Controller {
  static targets = ['colorCount', 'form', 'inputHeading'];

  updateDiscipline() {
    const discipline = this.formTarget.querySelector('input[name="quote[discipline]"]:checked').value;
    if (discipline === 'Embroidery') {
      this.inputHeadingTarget.textContent = 'Stitch Count';
      this.colorCountTargets.forEach((target) => {
        target.replaceChildren(inputStitchCount());
      });
    } else {
      this.inputHeadingTarget.textContent = 'Number of Colors';
      this.colorCountTargets.forEach((target) => {
        target.replaceChildren(inputColorCount());
      });
    }
  }
}
