import { Controller } from '@hotwired/stimulus';
import { navigator } from '@hotwired/turbo';
import { Modal } from 'bootstrap';

export default class extends Controller {
  static targets = ['dynaModal'];

  initialize() {
    this.turboEl = document.getElementById('dyna_modal');
    this.resetModalHandle = this.resetModalFrame.bind(this);
    this.dynaModalTarget.addEventListener('hidden.bs.modal', this.resetModalHandle);
    this.modal = this.myModal();
  }

  // Modal content load will trigger the connection to the stimulus controller
  connect() {
    // open modal if not already
    if (this.modal._isShown === false) { /* eslint no-underscore-dangle: "off" */
      this.modal.show();
    }
  }

  disconnect() {
    // trash modal when controller is un-loaded & modal is closed (cancel)
    if (this.modal._isShown === false) {
      this.modal.dispose();
    }
  }

  // get or set bootstrap modal
  myModal() {
    let modal = Modal.getInstance(this.dynaModalTarget);
    if (modal) {
      return modal;
    }
    modal = new Modal(this.dynaModalTarget, {});

    return modal;
  }

  // Turbo will not allow a second click to open the same modal (dom_id) element;
  // i.e. open->cancel->open. One can open a different element after cancel;
  // i.e. open->cancel->open-another-modal.
  // resetting the #dyna_modal.src value will allow Turbo to "reset" the link visit
  // See: https://github.com/hotwired/turbo/pull/263
  resetModalFrame() {
    this.turboEl.src = ''; // ensures the same button may be clicked again to re-open modal
    this.turboEl.innerHTML = ''; // will trigger this.disconnect()
    this.dynaModalTarget.removeEventListener('hidden.bs.modal', this.resetModalHandle);
  }

  // Used to submit a form for which the currentTarget element is outside of the form element.
  // e.g. the submit button is located in the footer of a modal
  externalSubmit(e) {
    e.preventDefault();
    navigator.submitForm(this.dynaModalTarget.querySelector('.modal-body > form'));
  }

  // action: "turbo:submit-end->turbo-modal#closeModal"
  closeModal(e) {
    if (e.detail.success) {
      // Hide modal, turbo content shall be loaded into containing page on success.
      this.modal.hide();
    }
  }
}
