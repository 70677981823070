import { Controller } from '@hotwired/stimulus';
import fetchTurboFrame from '../utils';

export default class extends Controller {
  static targets = [
    'lineItemPersonalization',
  ];

  addNewPersonalization(event) {
    event.preventDefault();
    const btn = event.currentTarget;
    fetchTurboFrame(`${btn.href}?line_item_personalization_index=${this.lineItemPersonalizationTargets.length}`);
  }

  dropLineItemPersonalization(event) {
    const delBtn = event.currentTarget;
    const personalizationIndex = this.lineItemPersonalizationTargets
      .findIndex((t) => t.contains(delBtn));
    const lineItemPersonalizationNode = this.lineItemPersonalizationTargets[personalizationIndex];

    if (!delBtn.dataset.lineItemPersonalizationIdValue) {
      lineItemPersonalizationNode.remove();
    } else {
      const destroyInput = lineItemPersonalizationNode.querySelector('input[name*=destroy]');
      const destroyToggle = destroyInput.value === 'true';

      // flag hidden destroy field
      destroyInput.setAttribute('value', !destroyToggle);
      lineItemPersonalizationNode.querySelectorAll('.faux-label,label,table').forEach((item) => {
        item.classList.toggle('text-decoration-line-through', !destroyToggle);
      });
      lineItemPersonalizationNode.querySelectorAll('input[type=checkbox]').forEach((item) => {
        if (destroyToggle) {
          item.removeAttribute('disabled');
        } else {
          item.setAttribute('disabled', 'disabled');
        }
      });

      if (!destroyToggle) {
        delBtn.classList.remove('text-secondary');
        delBtn.classList.add('text-tertiary');
        delBtn.querySelector('svg').classList.remove('fa-trash');
        delBtn.querySelector('svg').classList.add('fa-trash-undo');
      } else {
        delBtn.classList.remove('text-tertiary');
        delBtn.classList.add('text-secondary');
        delBtn.querySelector('svg').classList.remove('fa-trash-undo');
        delBtn.querySelector('svg').classList.add('fa-trash');
      }
    }
  }
}
