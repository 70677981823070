import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['username', 'usernameCheckbox'];

  inviteByUsername() {
    this.usernameTargets.forEach((target) => {
      target.hidden = !this.usernameCheckboxTarget.checked;
    });
  }
}
