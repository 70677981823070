import { Turbo } from '@hotwired/turbo-rails';

const fetchTurboFrame = (url, frameId = null) => {
  fetch(url, {
    headers: {
      Accept: 'text/vnd.turbo-stream.html',
      'Turbo-Frame': frameId,
    },
  })
    .then((r) => r.text())
    .then((html) => Turbo.renderStreamMessage(html));
};

export default fetchTurboFrame;
