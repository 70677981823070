import { Controller } from '@hotwired/stimulus';
import fetchTurboFrame from '../utils';

const collectInputs = (target) => target.querySelectorAll('.form-control,.form-select,.form-check-input');

export default class extends Controller {
  static targets = [
    'lineItemArt',
  ];

  addNewArtwork(event) {
    event.preventDefault();
    const btn = event.currentTarget;
    fetchTurboFrame(`${btn.href}?line_item_artwork_index=${this.lineItemArtTargets.length}`);
  }

  destroyArtwork(event) {
    const btn = event.currentTarget;
    const lineItemArtIndex = this.lineItemArtTargets.findIndex((t) => t.contains(btn));
    const artworkNode = this.lineItemArtTargets[lineItemArtIndex];

    if (!btn.dataset.lineItemArtworkIdValue) {
      // simply drop new (unsaved) lineItemArtwork
      artworkNode.remove();
    } else {
      const destroyInput = artworkNode.querySelector('input[name*=destroy]');
      const destroyToggle = destroyInput.value === 'true';
      destroyInput.setAttribute('value', !destroyToggle);
      collectInputs(artworkNode).forEach((input) => {
        if (!destroyToggle) {
          input.setAttribute('disabled', 'disabled');
        } else {
          input.removeAttribute('disabled');
        }
      });

      if (!destroyToggle) {
        btn.classList.remove('text-secondary');
        btn.classList.add('text-tertiary');
        btn.querySelector('svg').classList.remove('fa-trash');
        btn.querySelector('svg').classList.add('fa-trash-undo');
      } else {
        btn.classList.remove('text-tertiary');
        btn.classList.add('text-secondary');
        btn.querySelector('svg').classList.remove('fa-trash-undo');
        btn.querySelector('svg').classList.add('fa-trash');
      }
    }
  }
}
