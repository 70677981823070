import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['prices'];

  updateAvgPrice(event) {
    this.constructor.markDestroyed(event);
    let total = 0;
    let count = 0;
    this.pricesTargets.forEach((element) => {
      if (element.value === '') {
        return;
      }
      total += parseFloat(element.value);
      count += 1;
    });

    const productPrice = (count !== 0) ? (total / count).toFixed(2) : 0;
    document.querySelector('#avg_prod_price').innerText = `$${productPrice}ea`;
  }

  static markDestroyed(event) {
    const priceInput = event.currentTarget;
    priceInput.previousElementSibling.setAttribute('value', priceInput.value === '');
  }
}
