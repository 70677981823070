import { Controller } from '@hotwired/stimulus';
import fetchTurboFrame from '../utils';

export default class extends Controller {
  static targets = [
    'lineItemArt',
  ];

  static values = {
    index: Number,
    url: String,
  };

  selectNewArtwork() {
    fetchTurboFrame(this.fullArtworkUrl());
  }

  fullArtworkUrl() {
    const url = new URL(this.urlValue, window.location.origin);
    // Pluck the artwork_id
    const artworkEl = this.lineItemArtTarget.querySelector('select[name*="artwork_id"]');
    url.searchParams.append('line_item_artwork[artwork_id]', artworkEl.value);

    const artworkIdEl = this.lineItemArtTarget.querySelector('input[name*="id"]');
    if (!artworkIdEl.value) {
      // New artwork, retain the current placement & notes values
      const newArtworkParams = this.lineItemArtTarget.querySelectorAll('input[type=text], textarea');
      // Strip the nested attributes index from the input name
      const regex = new RegExp(`line_item\\[line_item_artworks_attributes\\]\\[${this.indexValue}\\]\\[(\\w+)\\]$`);
      // create & append the expected parameters to the existing url.searchParams
      newArtworkParams.forEach((input) => {
        const key = input.name.replace(regex, '$1');
        url.searchParams.append(`line_item_artwork[${key}]`, input.value);
      });
    }

    return url.toString();
  }
}
