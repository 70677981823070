import Rollbar from 'rollbar';
import metaContent from './meta-tags';

const rollbarEnv = metaContent('app_env');
const rollbar = new Rollbar({
  accessToken: metaContent('rollbar_access_token'),
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: rollbarEnv === 'production' || rollbarEnv === 'staging',
  payload: {
    environment: rollbarEnv,
  },
});

export { Rollbar, rollbar };
