import { Controller } from '@hotwired/stimulus';
import fetchTurboFrame from '../utils';

// Connects to data-controller="international-address"
export default class extends Controller {
  static values = { form: String, url: String };

  selectCountry(event) {
    this.country = event.currentTarget.selectedOptions[0].value;

    const url = new URL(this.urlValue);
    const params = new URLSearchParams(url.search);
    params.append('address_form', this.formValue);
    params.append('country_code', this.country);
    url.search = params.toString();
    fetchTurboFrame(url.toString());
  }
}
