import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

// ColorRun management
export default class extends Controller {
  static targets = ['listing', 'colorRuns'];

  static values = {
    colorWayDiscipline: String,
    colorWayEl: String,
    colorWayId: Number,
    colorWayIndex: Number,
    colorRunId: Number,
  };

  connect() {
    this.crIndex = this.colorRunsTargets.length;
  }

  newColorRun(e) {
    e.preventDefault();
    // COMBAK: only screen print has a maximum of 12 color runs
    if (this.colorRunsTargets.length === 12) {
      return false;
    }

    return fetch(`/color_runs/new?${this.colorRunParams()}`, {
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
      },
    })
      .then((resp) => resp.text())
      .then((html) => {
        this.crIndex += 1; // ensure any new nested set has new index
        Turbo.renderStreamMessage(html);
      });
  }

  destroyColorRun(event) {
    event.preventDefault();
    const delBtn = event.currentTarget;
    const colorRunIndex = this.colorRunsTargets.findIndex((t) => t.contains(delBtn));
    const colorRunNode = this.colorRunsTargets[colorRunIndex];

    if (!delBtn.dataset.colorRunIdValue) {
      this.listingTarget.removeChild(colorRunNode);
    } else {
      // disable value inputs for persisted color ways
      colorRunNode.querySelectorAll('button,input[type="text"],input[type="checkbox"]').forEach((item) => {
        item.setAttribute('disabled', 'disabled');
      });
      // flag hidden destroy field
      colorRunNode.querySelector('input[name*=destroy]').setAttribute('value', true);
    }
  }

  colorRunParams() {
    const params = new URLSearchParams();
    const discipline = (this.colorWayDisciplineValue !== '') ? this.colorWayDisciplineValue : document.querySelector('input[name="artwork[produceable_type]"]:checked').value;
    params.append('color_way_id', this.colorWayIdValue);
    params.append('color_way_index', this.colorWayIndexValue);
    params.append('color_way_el', this.colorWayElValue);
    params.append('color_run_count', this.crIndex);
    params.append('produceable_type', discipline);

    return params;
  }
}
