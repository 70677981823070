import { Controller } from '@hotwired/stimulus';
import fetchTurboFrame from '../utils';

export default class extends Controller {
  static targets = [];

  static values = {
    form: String,
    url: String,
  };

  selectChange(event) {
    const params = new URLSearchParams();
    params.append('address_form', this.formValue);
    params.append('address_book_id', event.currentTarget.selectedOptions[0].value);

    fetchTurboFrame(`${this.urlValue}?${params}`);
  }
}
