import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['option', 'existingAccount', 'newAccount'];

  toggleOptions() {
    if (this.radioValue() === 'existing_account') {
      this.existingAccountTarget.hidden = false;
      this.newAccountTarget.hidden = true;
    } else {
      this.existingAccountTarget.hidden = true;
      this.newAccountTarget.hidden = false;
    }
  }

  radioValue() {
    let val;
    this.optionTargets.forEach((element) => {
      if (element.checked) {
        val = element.value;
      }
    });

    return val;
  }
}
