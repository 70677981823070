import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="repeat-art"
export default class extends Controller {
  static targets = ['reference', 'toggler'];

  static values = {
    reference: String,
  };

  toggleRepeatArt() {
    this.referenceTarget.value = this.togglerTarget.checked ? this.referenceValue : '';
  }
}
