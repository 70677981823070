import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['hideable'];

  showTargets() {
    this.hideableTargets.forEach((el) => {
      el.hidden = false;
    });
  }

  hideTargets() {
    this.hideableTargets.forEach((el) => {
      el.hidden = true;
    });
  }

  toggleTargets() {
    this.hideableTargets.forEach((el) => {
      el.hidden = !el.hidden;
    });
  }

  toggleTargetByValue(el) {
    this.hideableTargets.forEach((target) => {
      // if data-visibility-visible-value is set to the toggled target value, show it
      target.hidden = target.dataset.visibilityVisibleValue !== el.currentTarget.value;
    });
  }
}
