document.addEventListener('DOMContentLoaded', () => {
  function resize() {
    const scrollElement = document.querySelector('.scroll_snippet');
    const table = document.querySelector('#table-responsive table tbody');
    const tableWidth = table.offsetWidth;

    if (tableWidth + 5 > window.outerWidth) {
      scrollElement.classList.remove('d-none');
    } else {
      scrollElement.classList.add('d-none');
    }
  }

  if (document.querySelector('.scroll_snippet')) {
    window.onresize = resize;
    window.onload = resize;
  }
});
