import { Controller } from '@hotwired/stimulus';
import fetchTurboFrame from '../utils';

// toggles the corresponding production type pane, only useful for new Artwork objects
export default class extends Controller {
  static targets = ['pane', 'radio', 'placement'];

  connect() {
    this.currentRadio = this.radioValue();
  }

  fetchPlacements() {
    fetchTurboFrame(`/art_placements?${this.placementParams()}`);
  }

  radioValue() {
    let val;
    this.radioTargets.forEach((element) => {
      if (element.checked) {
        val = element.value;
      }
    });

    return val;
  }

  placementParams() {
    const params = new URLSearchParams();
    params.append('klass', this.radioValue());
    params.append('placement_select', this.placementTarget.id);
    params.append('selected', this.placementTarget.selectedOptions[0].value);

    return params;
  }

  paneChange() {
    this.currentRadio = this.radioValue();
    this.fetchPlacements();
    this.toggleProductionPane();
    this.toggleFlashFields();
  }

  toggleProductionPane() {
    this.paneTargets.forEach((element) => {
      const el = element;
      el.hidden = this.currentRadio !== el.id;
    });
  }

  toggleFlashFields() {
    document.querySelectorAll('.flash-flag').forEach((element) => {
      const el = element;
      el.hidden = this.currentRadio !== 'Screen';
    });
  }
}
