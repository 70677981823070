import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

// ColorWay management
export default class extends Controller {
  static targets = ['listing', 'btn', 'colorWays', 'colorRuns'];

  static values = {
    artworkId: Number,
    discipline: String,
  };

  connect() {
    this.cwIndex = this.colorWaysTargets.length;
  }

  newColorWay(e) {
    e.preventDefault();

    return fetch(`/color_ways/new?${this.colorWayParams()}`, {
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
      },
    })
      .then((resp) => resp.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
        this.cwIndex += 1; // ensure any new nested set has new index
        // current software is limited to a max of 10 color ways
        if (this.colorWaysTargets.length === 9) {
          this.btnTarget.classList.toggle('d-none');
        }
      });
  }

  destroyColorWay(event) {
    event.stopPropagation();
    event.preventDefault();
    const delBtn = event.currentTarget;
    const colorWayIndex = this.colorWaysTargets.findIndex((t) => t.contains(delBtn));
    const colorWayNode = this.colorWaysTargets[colorWayIndex];

    if (!delBtn.dataset.colorWayIdValue) {
      this.listingTarget.removeChild(colorWayNode);
    } else {
      // disable value inputs for persisted color ways
      const inputs = colorWayNode.querySelectorAll('button,input[type="text"],input[type="checkbox"]');
      Array.prototype.forEach.call(inputs, (el) => {
        el.setAttribute('disabled', 'disabled');
      });
      // flag hidden destroy field
      colorWayNode.querySelector('input[type="hidden"]').setAttribute('value', true);
    }
  }

  colorWayParams() {
    const params = new URLSearchParams();
    const discipline = (this.artworkIdValue === 0) ? document.querySelector('input[name="artwork[produceable_type]"]').value : this.disciplineValue;
    let colorRunCount = 4;
    if (this.colorWaysTargets.length > 0) {
      colorRunCount = this.colorWaysTargets[0].querySelectorAll('div[data-color-run-target="colorRuns"]').length;
    }
    params.append('artwork_id', this.artworkIdValue);
    params.append('color_runs_count', colorRunCount);
    params.append('color_ways_index', this.cwIndex);
    params.append('color_ways_count', this.colorWaysTargets.length);
    params.append('produceable_type', discipline);

    return params;
  }
}
