import { Controller } from '@hotwired/stimulus';

// Sets the hidden destroy field to true when adjustment is emptied
export default class extends Controller {
  static targets = ['adjustment', 'destroy'];

  connect() {
    this.boundEventHandler = this.eventHandler.bind(this);
    Array.prototype.forEach.call(this.adjustmentTargets, (el) => el.addEventListener('change', this.boundEventHandler));
  }

  disconnect() {
    Array.prototype.forEach.call(this.adjustmentTargets, (el) => el.removeEventListener('change', this.boundEventHandler));
  }

  eventHandler(event) {
    const adjustmentInput = event.currentTarget;

    if (adjustmentInput.value !== '') {
      return;
    }

    const adjustmentIndex = this.adjustmentTargets.findIndex((t) => t.contains(adjustmentInput));

    this.destroyTargets[adjustmentIndex].setAttribute('value', true);
  }
}
