import { Controller } from '@hotwired/stimulus';
import fetchTurboFrame from '../utils';

export default class extends Controller {
  static targets = ['accountSelect', 'ccdSalespersonSelect', 'firmShipDate', 'salespersonSelect'];

  static values = {
    optionPricesUrl: String,
    url: String,
  };

  firmShipDateChange() {
    fetchTurboFrame(`${this.optionPricesUrlValue}?firm_ship_date=${this.firmShipDateTarget.checked}`);
  }

  selectChange() {
    fetchTurboFrame(`${this.urlValue}?${this.params()}`);
  }

  params() {
    const params = new URLSearchParams();
    params.append('account_id', this.accountSelectTarget.value);
    params.append('ccd_sales_rep_select', this.ccdSalespersonSelectTarget.id);
    params.append('sales_rep_select', this.salespersonSelectTarget.id);
    return params;
  }
}
