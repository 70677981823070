import { Controller } from '@hotwired/stimulus';
import fetchTurboFrame from '../utils';

export default class extends Controller {
  static targets = ['lineItemPersonalization'];

  static values = {
    index: Number,
    url: String,
  };

  selectPersonalization() {
    const url = new URL(this.urlValue, window.location.origin);
    // Pluck the artwork_id
    const personalizationEl = this.lineItemPersonalizationTarget.querySelector('select[name*="personalization_list_id"]');
    url.searchParams.append('line_item_personalization[personalization_list_id]', personalizationEl.value);

    const personalizationIdEl = this.lineItemPersonalizationTarget.querySelector('input[name*="id"]');
    if (!personalizationIdEl.value) {
      // New artwork, retain the current location values
      const newPersonalizationLocationEl = this.lineItemPersonalizationTarget.querySelector('select[name*=location]');
      // create & append the expected parameters to the existing url.searchParams
      url.searchParams.append('line_item_personalization[location]', newPersonalizationLocationEl.value);
    }

    fetchTurboFrame(url.toString());
  }
}
