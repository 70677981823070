import { Controller } from '@hotwired/stimulus';
import fetchTurboFrame from '../utils';

export default class extends Controller {
  static targets = ['mainSelect', 'secondarySelect', 'tertiarySelect'];

  static values = {
    lineItemArtwork: Number,
    url: String,
  };

  selectChange() {
    fetchTurboFrame(`${this.urlValue}?${this.params()}`);
  }

  selectValue() {
    return this.mainSelectTarget.selectedOptions[0].value;
  }

  params() {
    const params = new URLSearchParams();
    params.append('artwork_id', this.selectValue());
    if (this.lineItemArtworkValue > 0) { // new lineItemArtwork
      params.append('line_item_artwork_id', this.lineItemArtworkValue);
    }
    params.append('placement_select', this.secondarySelectTarget.id);
    params.append('color_way_select', this.tertiarySelectTarget.id);
    // params.append('selected', this.selectedValue);
    return params;
  }
}
