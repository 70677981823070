import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['formFilter'];

  connect() {
    this.formFilterTarget.querySelectorAll("input[name='q[discipline_cont][]']").forEach((checkbox) => {
      checkbox.addEventListener('change', this.submitForm.bind(this));
    });
  }

  submitForm() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.formFilterTarget.closest('form').submit();
    }, 750);
  }
}
