import './rollbar';
import '@hotwired/turbo-rails';
import '@rails/actiontext';
import * as ActiveStorage from '@rails/activestorage';

import 'trix';
// import 'bootstrap';
// import '@popperjs/core';

import '@fortawesome/fontawesome-pro/js/all';
// import './channels';
import './controllers';
import './scrolling_snippet';

ActiveStorage.start();
