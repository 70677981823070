import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  initialize() {
    this.messageBody = document.querySelector('#message_body');
  }

  resetForm() {
    this.element.reset();
  }

  validateMessage(e) {
    if (!this.messageBody.value) {
      e.detail.formSubmission.stop();
    }
  }
}
