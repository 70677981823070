import Reveal from 'stimulus-reveal-controller';

export default class extends Reveal {
  static targets = ['pdf', 'html'];

  connect() {
    super.connect();
  }

  toggleByOpt(e) {
    this.selectedOpt = e.currentTarget.value;
    if (this.selectedOpt === 'pdf') {
      this.htmlTarget.classList.add(this.hiddenClass);
      this.pdfTarget.classList.remove(this.hiddenClass);
    } else if (this.selectedOpt === 'html' || this.selectedOpt === 'video') {
      this.htmlTarget.classList.remove(this.hiddenClass);
      this.pdfTarget.classList.add(this.hiddenClass);
    }
  }
}
